import React from "react";
import Layout from "../components/Layout";
import { BlueCurve1, GrayCurve1 } from "../components/Curves";
import { PricingSection, FaqSection, CtaSection } from "../sections";

const PricingPage = () => {
  return (
    <Layout title="ChatIQ | Pricing">
      <PricingSection />
      <BlueCurve1 className="mt-n15" />
      <FaqSection />
      <CtaSection />
      <GrayCurve1 />
    </Layout>
  );
};

export default PricingPage;
